<template>
  <PulseLoader v-if="loading"/>
  <div
    v-else-if="notStreaming"
    class="img-container"
  >
    <div>
      <h2>Oops...<br /><br />Le contrôle de la musique n'est pas activé.</h2>
      <img :src="require('@/assets/forbidden.png', )" />
      <div class="button-container">
        <button @click="checkSpotify">Réessayer</button>
      </div>
      <div class="button-container">
          <button
            class="secondary"
            @click="$router.go(-1,)"
          >
          Retour
          </button>
        </div>
    </div>
  </div>
  <div v-else>
    <div class="radio-content">
      <h2 class="radio-title">
        Bienvenue sur l'Absolu Radio
        <br/><i>Salle performance</i>
      </h2>
      <div class="search-bar">
        <i class="fa fa-music" />
        <input
          v-model="textSearch"
          type="text"
          placeholder="Artiste, titre, genre ..."
          @keydown.enter="search(null, $event, )"
        />
        <i
          v-if="textSearch"
          class="fa fa-times"
          @click="textSearch = ''"
        />
        <i
          class="fa fa-search"
          @click="search()"
        />
      </div>

      <PulseLoader v-if="searchResults === 'loading'" />
      <div
        v-else-if="searchResults && searchResults.length"
        class="songs results"
      >
        <a
          class="blue-link"
          @click="searchResults = null"
        >
          <i class="fa fa-arrow-left" />
          Retour
        </a>
        <h3>Résultats&nbsp;:</h3>
        <ul class="song-list" >
          <li
            v-for="song in searchResults"
            :key="song.id_interne || song.id"
            class="song"
            @click="addDemande(song, )"
          >
            <img :src="song.image"/>
            <div>
              <div>
                {{song.title}}
                <i v-if="song.artists">
                  - {{song.artists}}
                </i>
              </div>
            </div>
          </li>
        </ul>
        <p class="more">
          Modifiez la recherche pour plus de résultats.
        </p>
      </div>
      <Songs
          v-else
          ref="songs"
          spotify
          @banned="banned=true"
          @not-streaming="notStreaming = true"
        />
    </div>
  </div>
</template>

<script>
// import SpotifyQueue from "@/components/SpotifyQueue.vue";
import Songs from "@/components/Songs.vue";
import Api from "@/modules/axios";

export default {
  name: "RadioSpotify",
  components: {
    // SpotifyQueue,
    Songs,
  },
  data() {
    return {
      loading: false,
      textSearch: "",
      searchResults: null,
      notStreaming: false,
    };
  },
  mounted() {
    this.checkSpotify();
  },
  methods: {
    checkSpotify() {
      this.loading = true;
      Api().get("/demande/check_spotify")
        .then((res) => {
          this.notStreaming = res.data.notStreaming;
          this.loading = false;
        });
    },
    search() {
      this.searchResults = "loading";
      return Api().get(`/demande/spotify_search?text=${this.textSearch}`)
        .then((res) => {
          this.searchResults = res.data.results;
        });
    },
    addDemande(song) {
      Api().post("/demande/spotify_add/", { song })
        .then((res) => {
          if (res.data.ok) {
            this.$toast("Demande enregistrée");
          } else {
            this.notStreaming = true;
            this.$toast("La musique ne peut pas être contrôlée actuellement", true);
          }
        });
    },
  },
};
</script>

<style lang="scss">
.radio-content .spotify-queue {
  margin-top: 3rem;
}

.songs {
  margin-top: 1rem;
}
.results {
  h2 {
    text-align: left;
    margin: 1rem 0;
  }
  img {
    width: 50px;
  }
  li + li {
    margin-top: 0.5rem;
  }
}
</style>
