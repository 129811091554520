<template>
  <div class="songs">
    <PulseLoader v-if="loading" />
    <template v-else-if="songs.length">
      <h3>File d'attente&nbsp;:</h3>
      <ul
        class="song-list"
      >
        <li
          v-for="song in songs"
          :key="song.id"
          class="song"
        >
          <figure>
            <img
              :src="song.image"
            />
          </figure>
          <div>
            <div>{{song.titre}}</div>
            <div class="votes">
              <span
                class="up"
                :class="{active: song.voted === true, }"
                @click="vote(song, false, )"
                >
                <i class="far fa-thumbs-up" />
                <template v-if="!voteLoading[`${song.id}up`]">
                  {{ song.up }}
                </template>
                <template v-else>
                  <ClipLoader />
                </template>
              </span>
              <span
                class="down"
                :class="{active: song.voted === false, }"
                @click="vote(song, true, )"
              >
                <i class="far fa-thumbs-down" />
                <template v-if="!voteLoading[`${song.id}down`]">
                  {{ song.down }}
                </template>
                <template v-else>
                  <ClipLoader />
                </template>
              </span>
              <span
                v-if="isAdmin || song.user_created === user.id"
                @click="deleteSong(song, )"
              >
                <template v-if="!voteLoading[`${song.id}delete`]">
                  <i class="fa fa-times" />
                </template>
                <template v-else>
                  <ClipLoader />
                </template>
              </span>
            </div>
          </div>
        </li>
      </ul>
    </template>
    <div
      v-else
      class="img-container"
    >
      <div>
        <h2>Il n’y a pas encore de demandes...</h2>
        <img :src="require('@/assets/placeholder.png', )" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Api from "@/modules/axios";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

import { catchError } from "@/utils";

export default {
  name: "Songs",
  components: {
    ClipLoader,
  },
  props: {
    spotify: Boolean,
  },
  data() {
    return {
      songs: [],
      refreshInterval: null,
      voteLoading: {},
      loading: true,
    };
  },
  computed: {
    ...mapGetters(["isAdmin", "user"]),
  },
  mounted() {
    this.$store.commit("setAvatarShow", false);
    this.handleVisibilityChange();
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval);
    document.removeEventListener("visibilitychange", this.handleVisibilityChange);
  },
  methods: {
    handleVisibilityChange() {
      // Lorsque l'onglet du site est actif, on active le refresh des musiques
      if (document.visibilityState === "hidden") {
        clearInterval(this.refreshInterval);
      } else {
        this.getAllDemande();
        this.refreshInterval = setInterval(() => {
          this.getAllDemande();
        }, 5000);
      }
    },
    getAllDemande() {
      Api().get(`/demande/?uri_spotify__isnull=${!this.spotify}`)
        .then((res) => {
          this.songs = res.data;
          this.$emit("refreshed-songs", this.songs);
          if (this.loading) {
            this.loading = false;
          }
        });
    },
    deleteSong(song) {
      const key = `${song.id}delete`;
      this.$set(this.voteLoading, key, true);

      Api().delete(`/demande/${song.id}/`)
        .then(() => {
          this.getAllDemande();
        })
        .catch(catchError)
        .finally(() => {
          this.$set(this.voteLoading, key, false);
        });
    },
    vote(song, isDownVote) {
      const key = `${song.id}${isDownVote ? "down" : "up"}`;
      this.$set(this.voteLoading, key, true);
      Api().post(`/demande/${song.id}/vote/`, { is_downvote: isDownVote })
        .then((res) => {
          if (res.data.banned) {
            this.$emit("banned");
          }
          if (!res.data.ok) {
            this.$emit("not-streaming");
          }
          this.getAllDemande();
        })
        .catch(catchError)
        .finally(() => {
          this.$set(this.voteLoading, key, false);
        });
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/variables.scss" as var;

.votes {
  .v-spinner {
    display: inline-flex !important;
    margin-left: 3px;
  }
  .v-clip {
    height: 13px !important;
    width: 13px !important;
    border-color: rgb(161, 27, 34) rgb(204, 26, 36) transparent !important;
  }

  .active {
    &.up {
      color: var.$color-success;
    }
    &.down {
      color: var.$red-darker;
    }
  }
}
</style>
